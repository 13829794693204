import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/_layout";
import MarkdownContent from "@components/markdown/markdown-content";
import "./privacy.scss";

export default function PrivacyLayout({
  data: { mdx: { frontmatter: { title }, body: content } },
  pageContext
}) {
  return (
    <Layout title={title} {...pageContext}>
      <main id="main" className="PrivacyPage">
        <MarkdownContent>{content}</MarkdownContent>
      </main>
    </Layout>
  )
};

export const pageQuery = graphql`
query Privacy($id: String!) {
  mdx(id: { eq: $id }) {
      frontmatter {
        title,
      }
      body
    },
  }
`;